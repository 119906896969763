/* Module: S */
.active-video-banner,
.active-video-banner.active-video-banner__hinid {
	min-height: pxToRem(550);

    &-inner {
        padding:   pxToRem(40);
        padding-top: pxToRem(40);
    }

	&__text-wrapper {
        margin-top: pxToRem(35);

    	.active-video-banner__heading {
    		font-size: pxToRem(53);
            line-height: pxToRem(72);
            margin-bottom: pxToRem(41);
    	}
    	.active-video-banner__subheading {
            font-size: pxToRem(20);
            line-height: pxToRem(28);
    	}
    }

    &__button-wrapper {
        margin-top:     pxToRem(47);
    }
}
