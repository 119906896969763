// Match these values to the size of your spritesheet and grid size used
$sprites__icon-grid-size: 100px !default;
$sprites__sheet-width:    325px;
$sprites__sheet-height:   422px;
$sprites__src-png: "sprites.png";
$sprites__src-svg: "sprites.svg"; // Set this to an empty string to stop it outputting svg

// Sprite icons and grid positions
// Note: Sass doesn't support maps/lists with named properties so it's mimicked
// here by specifying the name in even params and the position in odd params
// Odd: Name
// Even: row #, col #, actual width, actual height
$sprites__icons:
    search, // Icon Name (formatted as suitable for css class)
    (0,     // Row index (starting at 0)
     0,     // Column index (starting at 0)
     20,    // Width (pixel)
     20),   // Height (pixel)

    arrow-left-white,
    (0, 1, 25, 18),

    arrow-right-white,
    (0, 2, 25, 18),

    arrow-right-black,
    (0, 3, 25, 18),

    arrow-small-right-green,
    (1, 0, 20, 13),

    close-black,
    (1, 1, 19, 19),

    close-green,
    (1, 2, 18, 18),

    chevron-right,
    (1, 3, 16, 28),

    chevron-left,
    (2, 0, 16, 28),

    external,
    (2, 1, 14, 14),

    hamburger,
    (2, 2, 26, 18),

    menu-white-down,
    (2, 3, 11, 6),

    menu-white-left,
    (3, 0, 6, 12),

    menu-white-up,
    (3, 1, 11, 6),

    pdf,
    (3, 2, 13, 16),

    plus,
    (3, 3, 17, 18),

    minus,
    (4, 0, 17, 3),

    upload,
    (4, 1, 13, 13),

    search-white,
    (4, 2, 20, 20)

    // Add your own sprites here, just watch for commas to make sure the list
    // format is valid. Obviously the last item in the list doesn't have a trailing ,
;

// Required for scale calculations (Internal calculations, you don't need to edit these)
$sprites__columns:        stripUnit($sprites__sheet-width/$sprites__icon-grid-size);
$sprites__rows:           stripUnit($sprites__sheet-height/$sprites__icon-grid-size);
$sprites__width:          $sprites__columns*$sprites__icon-grid-size;
$sprites__height:          $sprites__rows*$sprites__icon-grid-size;

@mixin sq-sprite-bg($scale: 1) {
    // The background image url
    background-color: transparent;
    background-repeat: no-repeat;
    @if $sprites__src-svg != "" {
        background-size: ($scale*stripUnit($sprites__width) + px) ($scale*stripUnit($sprites__height) + px);
        background-image: url({{file_dest}}/#{$sprites__src-svg});
        .no-svg & {
            background-image: url({{file_dest}}/#{$sprites__src-png});
        }
    } @else {
        background-image: url({{file_dest}}/#{$sprites__src-png});
    }
}

// Assign a background and width/height based on the spritesheet variables
// specified above
@mixin sq-sprite($name, $scale: 1) {
    $index: index($sprites__icons, $name);
    $positionIndex: nth( $sprites__icons, $index + 1);

    // Converted values to px
    $posY: (- nth($positionIndex, 1)*$sprites__icon-grid-size);
    $posX: (- nth($positionIndex, 2)*$sprites__icon-grid-size);
    $width: nth($positionIndex, 3) + px;
    $height: nth($positionIndex, 4) + px;

    width: round($scale*nth($positionIndex, 3)) + px;
    height: round($scale*nth($positionIndex, 4)) + px;
    background-position: $posX*$scale $posY*$scale;
}

// Create a psuedo element, either :before or :after and assign it
// a sprite background
@mixin sq-sprite-psuedo($name, $elem: "before", $scale: 1) {
    &:#{$elem} {
        @include sq-sprite-bg;
        display: block;
        @include sq-sprite($name);
        content: '';
        // Place any extra output supplied for the element
        @content;
    }
}
