/* Module: S */
.hindid {

    .hindid__wrapper + &__wrapper,
    &__wrapper,
    &__wrapper-title {
        padding: 2rem 2rem 2rem 7%;

        // Dot
        &::before {
            width: 2rem;
            height: 2rem;
            top: 3.2rem;
        }

        // Line
        &::after {
            transform: translateX(16px);
        }
    }

    &__wrapper-title {
      display: block;
      padding-top: 2.8125rem;
      padding-bottom: 0;

      h2 {
        line-height: 4rem;
        margin: 0;
      }
    }

    &__wrapper {
        &::after {
            top: 55px;
        }
    }

    &--button {
        display: inline-block;
    }
}
