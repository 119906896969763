/* Module: S */
.hindid {
  &-tiles__content {
    padding-left: 3rem;
  }

  &__wrapper {
    .card {
      flex-basis: 33%;
      max-width: 33%;
    }

    .card__image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .card__title {
      font-size: 1rem;
      line-height: 1.39;
      padding: 1.375rem 1.25rem;
      min-height: 8.4375rem;
    }

    .card__link {
      flex-direction: column;
      height: 100%;
    }
  }
}
