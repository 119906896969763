/* Module: S */
.global-menu {
  align-items: center;
  color: $black;
  display: flex;
  order: 2;
  transition: color .15s ease-in-out;
  will-change: color;

  .homepage & {
    color: $white;
  }

  .homepage .scrolled & {
    color: $black;
  }

  &__link.checked {
    &:after {
      opacity: 1;
      transform: translateX(1rem) translateY(-1.125rem);
    }
  }
  &__item:hover {
    .global-menu__link {
      &:after {
        opacity: 1;
        transform: translateX(1rem) translateY(-1.125rem);
      }
    }
  }

  &__trigger,
  &__trigger-label {
    display: none;
  }

  &__trigger:checked ~
  &__list {
    display: flex;
  }

  &__list {
    display: flex;
    position: static;
  }

  &__item {
    background-color: transparent;
    position: static;
  }

  &__item +
  &__item {
    border: 0;
    margin-left: 1.75rem;
  }

  &__link {
    background-color: transparent;
    font-weight: 600;
    padding: 1.5625rem 1rem;
    position: relative;

    &:hover,
    &:focus {
      background-color: transparent;

      &::after {
        opacity: 1;
        transform: translateX(1rem) translateY(-1.125rem);
      }

      + .menu-lvl2 {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.selected {
      background-color: transparent;
    }

    &.focused {

      &::after {
        opacity: 1;
        transform: translateX(1rem) translateY(-1.125rem);
      }

      + .menu-lvl2 {
        opacity: 1;
        pointer-events: all;
      }
    }

    &::after {
      background-color: $asparagus;
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      opacity: 0;
      position: absolute;
      transform: translateX(1rem) translateY(0);
      transition: opacity .15s ease-in-out .15s,
                  transform .15s ease-in-out .15s;
      width: calc(100% - 2rem);
      will-change: opacity, transform;
    }
  }

  .submenu__trigger,
  .submenu__trigger-label {
    display: none;
  }

  .submenu__trigger:checked {
    ~ .submenu__list {
      display: none;
    }
  }

  .submenu__list {
    display: none;
  }

  .submenu__link {
    color: $black;
    padding: 0;
  }

  .menu-lvl2 {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    transition: opacity .15s ease-in-out .15s;
    width: 800px;
    will-change: opacity;

    &:hover,
    &:focus {
      opacity: 1;
      pointer-events: all;
    }
  }

  .menu-lvl2__list {
    background-color: $white;
    columns: auto auto;
    display: flex !important;
    flex-wrap: wrap;
    max-width: 100vw;
    padding: pxToRem(29) pxToRem(34);
  }

  .menu-lvl2__item {
    background-color: transparent;
    break-before: column;
    cursor: pointer;
    flex: 0 0 31%;
    margin-bottom: pxToRem(10);
    position: relative;

    &:nth-child(3n-1) {
      flex: 1 0 27%;
      margin-left: 3%;
      margin-right: 3%;
    }

    + .menu-lvl2__item {
      border: 0;
    }
  }

  .menu-lvl2__link {
    background-color: transparent;
    display: inline-block;
    font-size: .9375rem;
    font-weight: 500;
    position: relative;

    &::after {
      background-color: $asparagus;
      bottom: -5px;
      content: '';
      height: 3px;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      transform: translateY(pxToRem(10));
      transition: opacity .15s ease-in-out, transform .15s ease-in-out;
      will-change: opacity, transform;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
      transform: translateY(0);
    }

    &.selected {
      background-color: transparent;
    }
  }
}

.checked + .submenu > .submenu__trigger:not(:checked) ~ .submenu__list {
  display: none;
}

.checked + .submenu > .submenu__trigger:checked ~ .submenu__list {
  display: block;
}
