/* Module: S */
.search-results__form {
  display: flex;
  margin-top: pxToRem(21);
  width: 100% !important;

  input {
    flex: 1;
  }

  button {
    margin: 0;
  }
}

.search-results {
  background-color: $white-two;
  flex: 1;
  flex-basis: 100%;
  padding: pxToRem(32) 0 pxToRem(135);

  .container {
    width: 100%;
  }
}

.search-results__summary {
  font-size: pxToRem(17);
  margin-bottom: pxToRem(31);
  text-align: center;
}

.search-results__query {
  font-style: italic;
  font-weight: 500;
}

.search-results__list {
  @extend %list-reset;
  margin-bottom: pxToRem(62-7);
}

.search-results__item {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: pxToRem(20) pxToRem(25);
  margin-bottom: pxToRem(2);
}

.search-results__item-title {
  font-size: pxToRem(22);
  font-weight: 500;
  color: $warm-grey-two;
  margin-bottom: pxToRem(12);
  margin-top: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.search-reults__item-desc {
  margin: 0;
}

.search-results__read-more {
  margin-left: auto;
  color: $off-green;
  font-size: pxToRem(16);
  font-weight: 600;
  padding-right: pxToRem(40);
  position: relative;
  margin-top: pxToRem(14);

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &::after {
    @include sq-sprite-bg(1);
    @include sq-sprite('arrow-small-right-green', 1);
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-white {
  background-color: $white;
}

// Pagination
.pagination {
  font-weight: 500;
  color: $black-four;
  display: flex;
  justify-content: center;
  font-size: pxToRem(15);
}

.pagination__list {
  @extend %list-reset;
  display: flex;
}

.pagination__item {
  display: inline-block;
  margin: 0 pxToRem(3);

  &.current {
    padding-bottom: pxToRem(5);
    border-bottom: 1px solid $off-green;
    padding: 0 pxToRem(10);
  }
}

.pagination__link {
  color: inherit;
  padding: 0 pxToRem(10);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.pagination__nav {
  padding: 0 pxToRem(10) pxToRem(3);
  color: inherit;
  transition: transform .2s ease-out;

  &.next:not(.disabled) {
    &:hover,
    &:focus {
      transform: translateX(4px);
    }
  }

  &.prev:not(.disabled) {
    &:hover,
    &:focus {
      transform: translateX(-4px);
    }
  }

  &.disabled {
    color: $greyish;
    cursor: default;
  }
}
