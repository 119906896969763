/* Module: S */
.global-footer {
  color: $warm-grey;
  font-family: "VIC";
  font-weight: 500;
  padding: pxToRem(27) 0 pxToRem(33);
  background-color: $white;
}

.global-footer a:not(.btn) {
  color: $warm-grey;
  text-decoration: none;
  transition: color .7s;

  path {
    transition: fill .5s;
  }

  &:hover,
  &:focus {
    color: $black-five;
    text-decoration: underline;

    path {
      fill: currentColor;
    }
  }
}

.footer__connect-section,
.footer__nav-section {
  border-bottom: 1px solid $divider-gray;
}

.logout-wrapper {
  border-bottom: 1px solid $divider-gray;
  margin-bottom: pxToRem(17);
  flex-basis: 100%;
}

.footer__connect-section {
  padding-bottom: pxToRem(11);
  margin-bottom: pxToRem(17);
}

.connect__header {
  font-size: pxToRem(17);
  font-weight: 600;
}

.connect__list {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.connect__link {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: pxToRem(14);
  padding: pxToRem(10) pxToRem(14) pxToRem(10) 0;
}

.connect__link-icon {
  margin-right: pxToRem(19);
}

.footer__nav-section {
  padding-bottom: pxToRem(22);
  margin-bottom: pxToRem(28);
}

.footer__nav {
  @extend %list-reset;
}

.footer__nav-item {
  font-size: pxToRem(14);
  // text-decoration: underline;
  text-decoration-color: rgba(0,0,0,0);
  color: transparent;
  transition: text-decoration-color .5s, color .5s;
  &:hover {
    text-decoration-color: rgba(0,0,0,1);
    color: $black-five;
    
    img {
      fill: $black-five;
    }
  }
}

.footer__nav-link {
  display: block;
  padding: pxToRem(10) 0;
}

.footer__copyright-section {
  margin-top: pxToRem(23);
  font-size: pxToRem(13);
  line-height: 1.6;
}