/* Module: S */
.hinid-drawer {
  display: flex;

  .interactive__tab,
  .interactive__tab--empty {
    width: 9.6875rem;
    min-width: 9.6875rem;
    height: 8.3125rem;
    text-align: center;

    &::after,
    &:hover::after {
      top: 50%;
      right: 0;
      left: auto;
      transform: translate(50%, -50%) rotate(45deg);
    }

    &:hover {
      color: $white;
    }
  }

  .interactive__tab--empty {
    &::before {
      display: none;
    }
  }

  &__content {
    width: calc(100% - 9.6875rem);
  }
}
