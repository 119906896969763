/* Module: S */
.hindid {

    &-text-image {
        padding-bottom: 0 !important;
        overflow: hidden;
    }

    &-text-image__wrapper {
        display: flex;
        flex-direction: column;
    }

    .hindid__wrapper--light &-text-image__text {
        h3,
        p {
            color: $black-five;
        } 

        a:not(.btn) {
            color: $black-two;
        }
    }

    &-text-image__text {
        padding-bottom: 2rem;
        padding-left: 2rem;

        h3,
        p {
            color: $white;
        }

        a:not(.btn) {
            color: $white;
            text-decoration: underline;
        }

        a:not(.btn):hover,
        a:not(.btn):focus {
            text-decoration: none;
        }

    }

    &-text-image__image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 15.625rem;
        margin: 0 -8%;

        &--link {
            transition: all .5s ease;

            &:hover,
            &:focus {
                outline: none;
                transform: scale(1.05) rotate(-1deg);
            }
        }
    }

}

