/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-font-colour;
    color: invert($base-font-colour);
}

td {
    border: 1px solid $base-font-colour;
}

/* Buttons */
.button {
    @include sq-transition(background-color .3s ease);
    background-color: $base-font-colour;
    color: invert($base-font-colour);
    display: inline-block;
    border: 0;
    padding: .5em 1em;
    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: darken($base-font-colour, 20%);
    }
}

.button--large {
    @include sq-font-size(1.5);
}

.button--small {
    @include sq-font-size(.8);
}

/* Quotes */
blockquote {
    position: relative;
    padding-left: 3em;
    margin: 2em 0;
    &:before {
        @include sq-font-size(5);
        color: $base-font-colour;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}

/* to fix title anchor behind sticky nav issue */
.regular-content{
    h3 > a {
        display:block;
        padding-top:75px;
    }
}