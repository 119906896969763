/* Module: S */
.cards-section {
  background-color: $white-seven;
  flex-basis: 100%;
  padding-bottom: pxToRem(47 - 12);
  padding-top: pxToRem(40);
}

.cards-section__header {
  font-size: pxToRem(22);
  margin-bottom: pxToRem(27);
  margin-top: 0;
}

.cards {
  @extend %list-reset;
}

.card {
  margin-bottom: pxToRem(12);
}

.card__image {
  height: pxToRem(110);
  object-fit: cover;
  width: pxToRem(112);
}

.card__link {
  align-items: flex-start;
  background-color: $white;
  color: inherit;
  display: flex;
  min-height: pxToRem(110);
  transition: transform .25s ease-out;

  &:hover,
  &:focus {
    transform: scale(1.04);
  }
}

.card__title {
  font-size: pxToRem(16);
  font-weight: 500;
  line-height: 1.25;
  padding: pxToRem(15);
  max-width: 100%;
}

// Resources variation
.cards-section--resources {
  background-color: $warm-grey-two;

  .cards-section__header {
    color: $white;
  }

  .card__link {
    align-items: center;
    background-color: $very-light-pink;
    color: $black-ten;
    display: flex;
    min-height: pxToRem(89);
    padding-right: pxToRem(50);
    position: relative;
    transition: background-color .15s ease-out;

    &:hover,
    &:focus {
      background-color: $pinkish-grey-three;
      transform: none;

      &::after {
        transform: translateY(-50%) translateX(-.875rem);
      }
    }

    &::after {
      @include sq-sprite-bg(1);
      @include sq-sprite('arrow-right-black', 1);
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-1rem);
      transition: transform .15s ease-in-out;
    }
  }
}
