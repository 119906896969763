/* Module: S */
.lhs-menu {
  color: $black-two;
  display: none;
  flex-basis: 33%;
  max-width: 33%;
  font-size: .9375rem;
  padding-right: 6.875rem;

  &__title {
    color: $warm-grey-two;
    font-size: 1.3125rem;
    font-weight: 600;
    margin: 0 0 1.5rem;
  }

  &__list,
  &-lvl2__list {
    @include sq-list-reset;
  }

  &__list {
    padding: 0 0 0 2.125rem;
  }

  &-lvl2__list {
    display: none;
    margin: 0 0 .5rem;
    padding-left: 1.25rem;
  }

  &__link,
  &-lvl2__link {
    color: inherit;
    display: block;
    padding: .625rem 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-lvl2__link {
    font-weight: 500;
  }

  &__item.current
  &__link,
  &-lvl2__item.current a {
    font-weight: 700;
    position: relative;

    &::before {
      @include sq-sprite-bg(1);
      @include sq-sprite('arrow-small-right-green', 1);
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-2.125rem);
    }
  }

  .current--parent {
    font-weight: 700;
  }



  &__item.current 
  &-lvl2__list,
  &__item.current--parent 
  &-lvl2__list {
    display: block;
  }

  &__item +
  &__item {
    border-top: 1px solid $white-eight;
  }
}
