/* Module: S */
.double-slider {

	&__description {
		max-width: 100%;
		flex-basis: 100%;
	}

	&__thumbs-wrapper {
		display: none;
		overflow: hidden;
	}

	&__thumbs {
		overflow: hidden;
	}

	&__thumb {
		min-height: pxToRem(59);
		min-width: pxToRem(83);
		width: pxToRem(83);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-right: pxToRem(2) solid $white;
		border-left: pxToRem(2) solid $white;

		&:focus {
			outline: 0;
		}
	}
}
