/* Module: S */
.login-popup {
	overflow-x: auto;
	
	&__text-col {
		padding-right: pxToRem(60);
	}

	&__content {
		margin: pxToRem(122) pxToRem(100);
	}
}