/* Module: S */
@media screen and (min-width: 85.375rem) {
    .hindid {

        .hindid__wrapper + &__wrapper,
        &__wrapper,
        &__wrapper-title {
            padding: 2rem 2rem 2rem calc((100% - 73.75rem) / 2);
        }

    }
}

.hindid {

    .hindid__wrapper + &__wrapper,
    &__wrapper,
    &__wrapper-title {

        // Dot
        &::before {
            width: 2.9375rem;
            height: 2.9375rem;
            top: 2.9rem;
        }

        // Line
        &::after {
            transform: translateX(23px);
        }
    }

    &__wrapper {
        &::after {
            top: 70px;
        }
    }

    &__wrapper-title {
      display: block;
      padding-top: 4.4375rem;
      padding-bottom: .3125rem;
    }

}
