/* Module: S */
.hero {
  padding-bottom: pxToRem(106);
  padding-top: pxToRem(119+72);


  h1 {
    font-size: pxToRem(53);
    text-align: center;
    font-family: 'VIC';
    font-weight: 600;
  }

  p:not(.hero__card-desc) {
    font-size: pxToRem(25);
    margin-bottom: pxToRem(97);
    text-align: center;
    font-family: 'VIC';
    line-height: pxToRem(34);
    font-weight: 500;
  }

}

.hero__card-icon {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: pxToRem(62);
  margin-bottom: pxToRem(19);
}

.hero__card {
  padding: pxToRem(34) pxToRem(34) pxToRem(32);
  position: relative;

  &:focus,
  &:hover {
    background-position: bottom 25px right 22px;
  }

  &::after {
    @include sq-sprite-bg(1);
    @include sq-sprite('arrow-right-white', 1);
    bottom: 1.5625rem;
    content: '';
    position: absolute;
    right: 1.5625rem;
  }
}

.hero__card--pink::after {
  @include sq-sprite('arrow-right-black', 1);
}

.hero__card-title {
  font-size: pxToRem(26);
  margin-bottom: pxToRem(19);
}

.hero__card-desc {
  display: block;
  font-size: pxToRem(17);
  font-weight: 500;
}

