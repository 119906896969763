/* Module: S */
.header {
  background-color: $very-light-pink;
  display: block;
  height: 4.5rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999999;
}

.logo {
  align-items: center;
  background-color: $warm-grey-two;
  color: $white;
  cursor: pointer;
  display: flex;
  float: left;
  height: 4.5rem;
  justify-content: center;
  padding: 0 1.125rem;
  width: auto;
  z-index: 2;

  br {
    display: inline;
  }

  .homepage & {
    background-color: $off-green;
  }

  &-text {
    color: inherit;
    font-size: .9375rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  &-text-light {
    font-weight: 400;
  }
}

.header-search {
  align-items: center;
  display: flex;
  float: right;
  font-size: 1rem;
  height: 4.5rem;
  order: 2;

  &__form {
    display: none;
    position: relative;
  }

  &__input {
    background-color: $white;
    border: 2px solid $white;
    color: $brownish-grey;
    font-weight: 500;
    margin: 0;
    padding: .75rem 0 .6875rem .625rem;
    transition: background-color .15s ease-in-out;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $brownish-grey;
      opacity: 1;
      transition: color .15s ease-in-out;
    }
  }

  .homepage &__input {
    background-color: transparent;
    color: $white;

    &::placeholder {
      color: $white;
    }
  }

  .scrolled &__input {
    background-color: $white;
    color: $brownish-grey;

    &::placeholder {
      color: $brownish-grey;
    }
  }

  &__submit {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: 2px;
    right: 2px;
    height: calc(100% - 4px);
    width: calc(2.625rem - 2px);
    transition: background-color .5s;
    will-change: background-color;
    border-radius: 0;

    g {
      transition: stroke .5s;
      will-change: stroke;
    }

    &:focus, &:hover {
      outline: 0;
      background-color: $black;
      
      border-radius: 0;

      g {
        stroke: $white;
      }
    }

    svg {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
    }

    g {
      stroke: $black;
    }
  }

  .homepage &__submit {
    g {
      stroke: $white;
    }
  }

  .scrolled &__submit {

    g {
      stroke: $black;
    }

    &:focus, &:hover {
      g {
        stroke: $white;
      }
    }
  }

  &__link {
    display: block;
    height: 100%;
    position: relative;
    width: 4.375rem;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &::after {
      @include sq-sprite-bg(1);
      @include sq-sprite('search', 1);
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  }
}
