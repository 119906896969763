/* Module: S */
.hero {
	// background: linear-gradient(to bottom, rgba(-three, .3), rgba(, .32));
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	position: relative;
	padding-top: pxToRem(100);
  	padding-bottom: pxToRem(45);
  	
	&__title-wrapper {
		background-size: 0;
		margin: 0;
		padding: 0;

		&:after {
			display: none;
		}
	}

	&__card {
	height: 100%;
	}

	.grid {
	    margin-top: 0;
	    position: relative;
	    z-index: 10;
	    min-height: auto;
	  }

	p:not(.hero__card-desc) {
	    margin-bottom: pxToRem(33);
	  }

	&:after {
		display: block;
	}

	&:before {
		content: "";
		width: 100%;
		height: pxToRem(72);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		background: -webkit-linear-gradient(rgba(0,0,0,.35) 0%, rgba(0,0,0,0) 100%);
		background: -o-linear-gradient(rgba(0,0,0,.35) 0%, rgba(0,0,0,0) 100%);
		background: linear-gradient(rgba(0,0,0,.35) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	}
}

