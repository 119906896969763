/* Module: S */
.hero {
  background-color: $white-seven;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: $white;
  flex-basis: 100%;
  flex: 1;
  padding-top: pxToRem(72);
  position: relative;
  z-index: 1;
  background-size: 0;

  &__title-wrapper {
    margin: 0 -8%;
    padding: 0 8%;
    padding-top: pxToRem(28);
    padding-bottom: pxToRem(273);
    position: relative;
    z-index: 0;

    &:after {
      background-color: rgba(0,0,0,0.5);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .grid {
    margin-top: pxToRem(-240);
    position: relative;
    z-index: 10;
    min-height: pxToRem(237)
  }


  &::after {
    background-color: rgba(0,0,0,0.5);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
  }
  
  &__overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.4);
    width: 100%;
    height: 100%;
  }

  .container {
    z-index: 3;
    position: relative;
  }

  h1 {
    color: $white;
    font-size: pxToRem(30);
    font-weight: 600;
    line-height: 1.17;
    margin-bottom: pxToRem(22);
    margin-top: 0;
  }
  p:not(.hero__card-desc) {
    color: $white;
    font-size: pxToRem(15);
    font-weight: 500;
    margin-bottom: 0;
  }
}

.hero__card {
  background-color: $white;
  color: $base-font-colour;
  display: block;
  padding: pxToRem(34) pxToRem(15) pxToRem(32);
  transition: all 0.2s ease-out;
}

.hero__card-icon {
  display: none;
}

.hero__card-title {
  color: inherit;
  font-size: pxToRem(18);
  font-weight: 600;
  margin: 0;
  text-decoration: underline;
}

.hero__card-desc {
  color: inherit;
  display: none;
}

.hero__card--pink {
  background-color: $very-light-pink;
  border: 1px solid $pink-card-border;

  &:focus,
  &:hover {
    background-color: darken($very-light-pink, 10%);
  }
}

.hero__card--peach {
  background-color: $dark-peach;
  color: $white;

  &:focus,
  &:hover {
    background-color: darken($dark-peach, 10%);
  }
}

.hero__card--green {
  background-color: $off-green;
  color: $white;

  &:focus,
  &:hover {
    background-color: darken($off-green, 10%);
  }
}

.hero__card--grey {
  background-color: $warm-grey-two;
  color: $white;

  &:focus,
  &:hover {
    background-color: darken($warm-grey-two, 10%);
  }
}
