/* Module: S */
.lhs-menu + .regular-content {
	.double-slider.less-than-5 .double-slider__footer {
		.double-slider__description {
			padding-right: 0;
			max-width: 35%;
			width: 35%;
			flex-basis: 35%;
		}
		.double-slider__thumbs-wrapper {
			max-width: 65%;
			width: 65%;
			flex-basis: 65%;
		}
	}
}

.double-slider {

	&.less-than-5 &{
		&__footer {
			.double-slider{
				&__description {
					max-width: 50%;
					width: 50%;
					flex-basis: 50%;
				}
				&__thumbs-wrapper {
					max-width: 50%;
					width: 50%;
					flex-basis: 50%;
					display: block;
				}
				&__thumbs {
					max-width: 100%;
				}
			}
		}
	}

	&__footer {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	&__description {
		max-width: 64%;
		flex-basis: 64%;
	}

	&__thumbs-wrapper {
		overflow: hidden;
		display: flex;
		flex-basis: 36%;
		justify-content: flex-end;
	}

	&__thumb {
		max-height: pxToRem(59);
		max-width: pxToRem(83);
		width: pxToRem(83);
		height: pxToRem(59);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&:focus {
			outline: 0;
		}
	}
}