/* Module: S */
.hinid-drawer {
  position: fixed;
  top: 4.5rem;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 500;
  height: calc(100vh - 4.5rem);
  transform: translateX(100%);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1s ease-out;

  &.active {
    transform: translateX(0%);
    opacity: 1;
  }
  iframe {
    max-width: 100%;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__visit {
    display: block;
    width: 3.6875rem;
    height: 3.6875rem;
    border: 1px solid $asparagus;
    position: relative;
    font-size: 1.25rem;

    &:hover {
      background-color: $asparagus;

      .hinid-drawer__arrow--SW,
      .hinid-drawer__arrow--NE {
        color: $white;
      }
    }
  }

  &__arrow--SW {
    position: absolute;
    display: block;
    line-height: .625rem;
    left: .5rem;
    bottom: .625rem;
    color: $asparagus;
  }

  &__arrow--NE {
    position: absolute;
    display: block;
    line-height: .625rem;
    top: .625rem;
    right: .5rem;
    color: $asparagus;
  }

  .interactive__tab,
  .interactive__tab--empty {
    background-color: $asparagus;
    color: $white;
    width: 100%;
    max-width: 100%;
    height: 6.5rem;
    flex-direction: column;

    &::before {
      background-color: $white;
      border-color: $white;
      color: $black;
      content: attr(data-number);
      display: block;
      position: absolute;
      width: 2.1875rem;
      height: 2.1875rem;
      top: .9375rem;
      left: .9375rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      transform: none;
    }

    &::after,
    &:hover::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
      display: block;
      content: "";
      width: .625rem;
      height: .625rem;
      background-color: $white;
    }

    &:hover {
      &::before {
        color: $black;
      }

      &::after {
        background-color: $white;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    span {
      display: block;
    }

    img {
      display: none;
    }

    img + img {
      display: block;
    }
  }

  .interactive__tab--empty {
    background-color: transparent;
    height: 0;
    &::before {
      display: none;
    }
  }

  &__content {
    background-color: $white;
    padding: 1.875rem 18px 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.hinid-drawer__close {
  width: 8rem;
  height: 3.6875rem;
  background-color: $asparagus;
  border: 2px solid $asparagus;
  color: $white;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background-color: $white;
    color: $black;

    .arrow-left {
      &::before {
        border-color: $black;
      }

      &::after {
        background-color: $black;
      }
    }
  }

  .arrow-left {
    display: inline-block;
    width: 1.125rem;
    height: 14px;
    position: relative;
    top: 1px;
    margin-right: .75rem;

    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: .625rem;
      height: .625rem;
      border-top: 1px solid $white;
      border-left: 1px solid $white;
      transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: $white;
      transform: translateY(-50%);
    }
  }
}
