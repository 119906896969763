/* Module: S */
.interactive__tabs {
  flex-direction: row;

  &.sticky {
    .interactive__tab {
      height: 4.5rem;
    }
  }
}

.interactive__tab {
  transition: height .5s ease;
  height: 8.25rem;
  z-index: 2;

  &::before {
    width: 2.1875rem;
    height: 2.1875rem;
    font-size: 1rem;
  }

  a {
    color: $black;
  }
}

.interactive__tab.active,
.interactive__tab:hover {
  color: $white;

  &::after {
    display: block;
    width: 2rem;
    height: 2rem;
    z-index: -1;
  }

  a {
    color: $white;
  }
}

.hindid_next {
  padding: 2rem 0 4rem 2rem;
}
