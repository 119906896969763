/* Module: S */
.global-footer {
  padding-top: pxToRem(59);
  padding-bottom: pxToRem(80);
  .container {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__connect-section,
.footer__nav-section,
.footer__logo-section,
.footer__copyright-section {
  flex: 1 0 50%;
  border: 0;
  padding: 0;
  margin: 0;
}

.connect__header {
  display: block;
  margin-bottom: pxToRem(11);
}

.connect__link {
  padding-right: pxToRem(55);
}

.footer__logo-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: pxToRem(26);
}

.footer__logo {
  margin-right: pxToRem(34);
}

.footer__copyright-section {
  margin-top: pxToRem(20);
  font-size: pxToRem(14);
}

.footer__nav-section {
  order: 1;
  display: flex;
  align-items: flex-end;
}

.footer__nav {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
}

.footer__nav-link {
  padding: 0 0 0 pxToRem(39);
}