/* Module: S */
.login-popup {
	width: 80vw;
	max-height: pxToRem(582);

	&__wrapper {
		overflow-x: unset;
		max-height: 100vh;
		overflow-y: auto;
	}

	form {
		max-width: pxToRem(380);
	}
}

