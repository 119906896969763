/* Module: S */
/*
 * Global styles
 */
$label-dark: $off-green;
$label-light: $white;

.read-more {

    // Read more label
    &__label {
            display: inline-block;
            padding: pxToRem(5) pxToRem(22);
            color: $label-dark;
            font-weight: 600;
            border: 2px solid $label-dark;

        // Label - hover/focus
        &:hover, &:focus {
            color: $label-light;
            background-color: $label-dark;
            cursor: pointer;
        }

        // // Label - expanded
        // &__state:checked ~ &__label {
        // }
    }
}

