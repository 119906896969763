/* Module: S */
.slick-slider {

	.slick-arrow {
		display: block;
	}

	.slick-dots {

		li {

			button {
				width: pxToRem(13);
				height: pxToRem(13);
			}
		}
	}

	.slick-slide {

		.slide__caption,
		p {
			// DISPLAY CAPTION ON MEDIUM
			display: none;
		}
	}
}
