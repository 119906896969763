/* Module: S */
.login-popup{
	background-color: $white;
	width: 100vw;
	overflow-x: auto;
	position: relative;
	align-items: center;
	max-width: pxToRem(1180);
	max-height: 100vh;
	margin: auto;

	&__content {
		margin: pxToRem(50) pxToRem(25);
		display: flex;
		flex-wrap: wrap;
		z-index: 9999;
	}

	&__close {
		@include sq-sprite-bg(1);
		@include sq-sprite('close-black', 1);
		cursor: pointer;
		position: absolute;
		right: pxToRem(26);
		top: pxToRem(26);
		opacity: .6;
		transition: opacity .3s;

		&:hover, &:focus {
			opacity: 1;
			outline: 0;
		}
	}

	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .51);
		z-index: 99999999999;
		overflow-x: auto;
		display: flex;
		padding: 0;
	}

	&__title {
		margin: 0;

		&:last-of-type {
			margin-top: pxToRem(54);
		}
	}
	&__paragraph {
		margin: 0;

		a {
			font-family: 'VIC';
			font-weight: bold;
			color: $black-three;
		}
	}

	&__link--forgot-pass {
		font-size: pxToRem(15);
		color: $black-six;
		font-weight: normal;
		margin-top: pxToRem(8);
		display: inline-block;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.form__item {

		&:first-of-type {
			margin-bottom: pxToRem(15);
		}

		label {
			width: 100%;
			line-height: pxToRem(15);
			font-size: pxToRem(15);
			font-family: 'VIC';
			font-weight: 500;
			color: $black-six;
		}
		input:not([type="submit"]) {
			width: 100%;
			height: pxToRem(55);
			border: 1px solid $black-three;
			padding-left: pxToRem(10);
		}

		input[type="submit"] {
			margin-top: pxToRem(54);
		}
	}
}

.loader {
	-ms-transform:      translate(-50%, -50%);
	-webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
	-webkit-transform:  translate(-50%, -50%);
	background:         $white;
	border-radius:      50%;
	box-shadow:         0px 0px 10px rgba(0,0,0,0.8);
	height:             pxToRem(60);
	left:               50%;
	position:           fixed;
	top:                50%;
	transform:          translate(-50%, -50%);
	width:              pxToRem(60);
	z-index:            2000;

	&-inner {
		border:        pxToRem(5) solid $warm-grey-three; 
		border-top:    pxToRem(5) solid $off-green;
		border-radius: 50%;
		margin:        pxToRem(14) auto 0 auto;
		width:         pxToRem(32);
		height:        pxToRem(32);
		-webkit-animation: spin 2s linear infinite;
				animation: spin 2s linear infinite;
	}
}

@-webkit-keyframes spin {
    0% { -webkit-transform:   rotate(0deg); transform:   rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform:   rotate(0deg); transform:   rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.login-wrapper {
	.login-popup__close {
		display: none;
	}
}
