/* Module: S */
.lhs-menu + .regular-content {
	.double-slider.less-than-5 .double-slider__footer {
		.double-slider__description {
			padding-right: 0;
			max-width: 50%;
			width: 50%;
			flex-basis: 50%;
		}
		.double-slider__thumbs-wrapper {
			max-width: 50%;
			width: 50%;
			flex-basis: 50%;
		}
	}
}

.double-slider {

	&.less-than-5 &{
		&__footer {
			.double-slider{
				&__description {
					max-width: 60%;
					width: 60%;
					flex-basis: 60%;
				}
				&__thumbs-wrapper {
					max-width: 40%;
					width: 40%;
					flex-basis: 40%;
				}
			}
		}
	}

	&__description {
		padding-right: 0;
	}
}