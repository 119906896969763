/* Module: S */
.cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.625rem;
}

.card {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: pxToRem(20);
  padding: 0 .625rem;
}
