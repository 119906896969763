/* Module: S */
.page-header {
  background-color: $white-two;
  padding: 1.5rem 0 3.625rem;

  h1 {
    font-size: 1.875rem;
    margin: 0;
  }
}

.breadcrumbs {
  @extend %list-reset;
}

.breadcrumbs__item {
  display: none;
  font-size: .9375rem;
  line-height: 1.125rem;
  padding-right: 1.25rem;

  &:not(:last-of-type)::after {
    content: '/';
    display: inline-block;
    padding-left: 1.25rem;
  }

  &:nth-last-child(1) {
    display: block;
    font-weight: 500;

    &::after {
      display: none;
    }
  }
}

.breadcrumbs__link {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
