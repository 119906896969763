/* Module: S */
.hinid-drawer {

  .interactive__tab,
  .interactive__tab--empty {
    width: 17.9375rem;
    min-width: 17.9375rem;
  }

  &__content {
    width: calc(100% - 17.9375rem);
  }
}
