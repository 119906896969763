/* Module: S */
.active-video-banner {
	min-height: pxToRem(342);

	&.active-video-banner__hinid {
		min-height: pxToRem(400);
	}

	&-inner {
        left:      50%;
        padding:   pxToRem(40);
        padding-top: pxToRem(10);
        position:  absolute;
        top:       50%;
        transform: translate(-50%, -50%);
        width:     100%;
	}

	&__video-bg--fallback {
		display:    none;
		visibility: hidden;
	}
	&__video-bg--loop {
		display:    block;
		visibility: visible;
	}
     &__toggle-button {
        @include sq-transition(all .35s ease);
        background-color:    rgba($black,.33);
        background-image:    url('{{file_dest}}/pause-button.svg');
        background-position: center center;
        background-repeat:   no-repeat;
        background-size:     40%;
        border:              none;
        border-radius:       100%;
        display:             block;
        height:              pxToRem(40);
        opacity:             .8;
        position:            absolute;
        right:               pxToRem(50);
        bottom:              pxToRem(25);
        width:               pxToRem(40);
        z-index:             1;

        &:focus,
        &:hover {
            background-color: $black;
            outline:          none;
            opacity:          1;
        }
    }
    .pause {
        background-image:    url('{{file_dest}}/play-button.svg');
    }
	&__text-wrapper {

        margin-top:     pxToRem(0);


        .active-video-banner__heading,
        .active-video-banner__subheading {
            text-align: center;
        }
    }
     &__button-wrapper {


        .btn {
            width: auto;
        }
    }

		.scroll-to-tabs {
			margin-bottom: 0;
		}
}
