/* Module: S */
.slick-slider {
  margin-bottom: pxToRem(48);

  a:hover {
    text-decoration: none;
    border: 0;
  }

  .slick-arrow {
    background-color: rgba(255, 255, 255, .7);
    border: 0;
    border-radius: 0;
    display: none;
    font-size: 0;
    height: pxToRem(72);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .4s ease-in-out;
    width: pxToRem(53);
    z-index: 100;

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 1);
      outline: 0;
    }

    &::after {
      @include sq-sprite-bg(1);
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(50%);
    }
  }

  .slick-next {
    right: 0;

    &::after {
      @include sq-sprite('chevron-right', 1);
    }
  }

  .slick-prev {
    left: 0;

    &::after {
      @include sq-sprite('chevron-left', 1);
    }
  }

  .slick-dots {
    background-color: $black-two;
    bottom: pxToRem(-30);
    display: block;
    display: flex;
    justify-content: center;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: pxToRem(9) 0 0;
    position: absolute;
    right: 0;

    li {
      cursor: pointer;
      padding: 0;

      button {
        background-color: $white;
        border-radius: pxToRem(50);
        border: none;
        height: 12px;
        margin-right: 7px;
        padding: 0;
        text-indent: -99999px;
        width: 12px;

        &:focus {
          outline: 0;
        }
      }

      &::before {
        display: none;
      }

      &.slick-active {

        button {
          background-color: $asparagus;
        }
      }

      + li {
        margin-top: 0;
      }
    }
  }

  .slick-slide {
    position: relative;

    // iframe {
    //   position: relative !important;
    // }

    .slide__caption,
    p {
      background-color: rgba(0,0,0, .42);
      color: $white;
      display: none;
      font-size: pxToRem(20);
      left: 0;
      line-height: pxToRem(20);
      margin: 0;
      padding: pxToRem(31) pxToRem(26);
      position: absolute;
      right: 0;
      text-align: left;
      top: 0;
    }
  }
}
