/* Module: S */
.no-js {
	.double-slider {
		&__footer {
			display: none;
		}
		&__screen {
			background-color: $white;
			&-slide {
				background-color: $white;
				margin-bottom: pxToRem(20);

				&:not(:first-of-type) {
					display: block;
					position: static;
				}
				p {
					margin-bottom: 0;
				}
			}
		}
	}
}

.double-slider {
	max-width: 100%;
	flex-basis: 100%;
	margin-bottom: pxToRem(20);

	.slick-arrow {
		display: none;
	}

	a:hover {
		text-decoration: none;
		cursor: pointer;
		border: 0;
	}
	
	&.less-than-5 &{
		&__footer {
			.slick-track {
				transform: translate3d(0,0,0) !important;
				margin: 0;
				float: right;
				width: auto !important;
				margin-right: pxToRem(-5);
			}
		}
	}


	&__screen {
		background-color: $white-two;

		&-slide {
			display: none;
			&:first-of-type {
				display: block;
			}

			&:not(.slick-initialised) {
				img {
					display: block;
					margin: 0 auto;
				}
			}

			&.slick-initialised {
				position: relative;
				width: auto;
				height: auto;
			}
		}

		&.slick-initialised {
			.double-slider__screen-slide {
				display: block;
			}
		}

		.slick-slide {
			position: relative;

			.caption {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				background-color: rgba(0,0,0, .42);
				min-height: pxToRem(79);
				display: block;
				line-height: pxToRem(20);
				font-size: pxToRem(20);
				margin: 0;
				color: $white;
			}
		}
	}
	
	&__thumb {
		&:not(:first-of-type) {
			position: absolute;
			width: 0;
			height: 0;
			top: 0;
			left: 0;
		}

		&:not(.slick-initialised) {
			img {
				display: block;
				margin: 0 auto;
			}
		}

		&.slick-initialised {
			position: relative;
			width: auto;
			height: auto;
		}
	}

	&__thumbs-wrapper {
		display: none;
	}



	&__description {
		max-width: 100%;
		flex-basis: 100%;
		padding-right: pxToRem(75);

		div[data-slick-desc-index]{
			&:not(:first-of-type) {
				display: none;
			}
			opacity: 1;
			transition: opacity 1s;
			

			&.double-slider__description--hide {
				display: none;
				animation-duration: 1s;
				animation-name: hide;
			}
		}

		h3 {
			font-size: pxToRem(17);
			line-height: pxToRem(25);
			text-align: left;
			color: $black-four;
		}

		p {
			font-size: pxToRem(15);
			line-height: pxToRem(25);
			color: $black-four;
			text-align: left;
			margin: 0;
		}
	}

	&__thumbs-wrapper {
		max-width: 100%;
		flex-basis: 100%;
	}

	&__footer {
		margin-top: pxToRem(18);
		display: flex;
	}

	&__description {

		h3 {
			margin-top: 0;
		}
	}

	.slick-slide {

		img {
			margin: 0 auto;
		}
	}
}