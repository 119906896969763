/* Module: S */
.hindid {
  &-tiles__content {
    padding-left: 6rem;
  }

  &-tab-content {
    .cards-section {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &__wrapper {
    .card {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}
