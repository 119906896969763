/* Module: S */
.interactive__tab {
  height: 6.5rem;
  font-weight: 500;
  color: $black;
  max-width: 100%;
  width: 100%;

  &::before {
    background-color: $white;
    content: counter(tab);
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    font-size: .8rem;
    top: .9375rem;
    left: .9375rem;
    border: 1px solid $black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    transform: none;
    z-index: 300;
  }

  img {
    display: block;
  }

  a {
    color: $black;

    img + img {
      display: none;
    }

    span {
      position: static;
    }
  }
}

.interactive__tab.active,
.interactive__tab:hover {
  color: $black;

  &::before {
    background-color: $white;
    border-color: $white;
    color: $black;
  }

  a {
    color: $white;

    img {
      display: none;

      + img {
        display: block;
      }
    }
  }
}

.interactive__tab-title--mobile {
  display: none;
}

.hindid_next {
  overflow: hidden;
  padding: 1rem 0 1rem 1rem;

  a {
    float: right;
  }
}
