
// FONTS
@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-ExtraLight.eot");
  src: url("mysource_files/hinted-VIC-ExtraLight.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-ExtraLight.woff2") format("woff2"), url("mysource_files/hinted-VIC-ExtraLight.woff") format("woff"), url("mysource_files/hinted-VIC-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-ExtraLightItalic.eot");
  src: url("mysource_files/hinted-VIC-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-ExtraLightItalic.woff2") format("woff2"), url("mysource_files/hinted-VIC-ExtraLightItalic.woff") format("woff"), url("mysource_files/hinted-VIC-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-Light.eot");
  src: url("mysource_files/hinted-VIC-Light.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-Light.woff2") format("woff2"), url("mysource_files/hinted-VIC-Light.woff") format("woff"), url("mysource_files/hinted-VIC-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-LightItalic.eot");
  src: url("mysource_files/hinted-VIC-LightItalic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-LightItalic.woff2") format("woff2"), url("mysource_files/hinted-VIC-LightItalic.woff") format("woff"), url("mysource_files/hinted-VIC-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-Regular.eot");
  src: url("mysource_files/hinted-VIC-Regular.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-Regular.woff2") format("woff2"), url("mysource_files/hinted-VIC-Regular.woff") format("woff"), url("mysource_files/hinted-VIC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-Italic.eot");
  src: url("mysource_files/hinted-VIC-Italic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-Italic.woff2") format("woff2"), url("mysource_files/hinted-VIC-Italic.woff") format("woff"), url("mysource_files/hinted-VIC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-Medium.eot");
  src: url("mysource_files/hinted-VIC-Medium.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-Medium.woff2") format("woff2"), url("mysource_files/hinted-VIC-Medium.woff") format("woff"), url("mysource_files/hinted-VIC-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-MediumItalic.eot");
  src: url("mysource_files/hinted-VIC-MediumItalic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-MediumItalic.woff2") format("woff2"), url("mysource_files/hinted-VIC-MediumItalic.woff") format("woff"), url("mysource_files/hinted-VIC-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-SemiBold.eot");
  src: url("mysource_files/hinted-VIC-SemiBold.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-SemiBold.woff2") format("woff2"), url("mysource_files/hinted-VIC-SemiBold.woff") format("woff"), url("mysource_files/hinted-VIC-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-SemiBoldItalic.eot");
  src: url("mysource_files/hinted-VIC-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-SemiBoldItalic.woff2") format("woff2"), url("mysource_files/hinted-VIC-SemiBoldItalic.woff") format("woff"), url("mysource_files/hinted-VIC-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-Bold.eot");
  src: url("mysource_files/hinted-VIC-Bold.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-Bold.woff2") format("woff2"), url("mysource_files/hinted-VIC-Bold.woff") format("woff"), url("mysource_files/hinted-VIC-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'VIC';
  src: url("mysource_files/hinted-VIC-BoldItalic.eot");
  src: url("mysource_files/hinted-VIC-BoldItalic.eot?#iefix") format("embedded-opentype"), url("mysource_files/hinted-VIC-BoldItalic.woff2") format("woff2"), url("mysource_files/hinted-VIC-BoldItalic.woff") format("woff"), url("mysource_files/hinted-VIC-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}