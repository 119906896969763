/* Module: S */
$gap-size: 0 pxToRem(10);
.grid--gaps {
  margin: $gap-size;
  [class^="col"] {
    padding: pxToRem(10);
  }
}

.col-100\@md { flex-basis: 100%; max-width: 100%; }
.col-95\@md { flex-basis: 95%; max-width: 95%; }
.col-90\@md { flex-basis: 90%; max-width: 90%; }
.col-85\@md { flex-basis: 85%; max-width: 85%; }
.col-80\@md { flex-basis: 80%; max-width: 80%; }
.col-75\@md { flex-basis: 75%; max-width: 75%; }
.col-70\@md { flex-basis: 70%; max-width: 70%; }
.col-65\@md { flex-basis: 65%; max-width: 65%; }
.col-60\@md { flex-basis: 60%; max-width: 60%; }
.col-55\@md { flex-basis: 55%; max-width: 55%; }
.col-50\@md { flex-basis: 50%; max-width: 50%; }
.col-45\@md { flex-basis: 45%; max-width: 45%; }
.col-40\@md { flex-basis: 40%; max-width: 40%; }
.col-35\@md { flex-basis: 35%; max-width: 35%; }
.col-33\@md { flex-basis: 33.333%; max-width: 33.333%; }
.col-30\@md { flex-basis: 30%; max-width: 30%; }
.col-25\@md { flex-basis: 25%; max-width: 25%; }
.col-20\@md { flex-basis: 20%; max-width: 20%; }
.col-15\@md { flex-basis: 15%; max-width: 15%; }
.col-10\@md { flex-basis: 10%; max-width: 10%; }

.grid {

	&--3x2, &--3x3, &--2x2, &--4x4 {
		div {
		  padding: pxToRem(10);
      width: 50%;
      flex-basis: 50%;
		}
	}

	&--3x2 {
			div:nth-child(5){
				max-width: 100%;
				flex-basis: 100%;
			}
	}
}
