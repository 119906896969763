/* Module: S */
.cards-section {
  padding-top: pxToRem(72);
  padding-bottom: pxToRem(95);
}

.cards-section__header {
  font-size: pxToRem(30);
  font-weight: 600;
  margin-bottom: pxToRem(50);
  text-align: center;
}

.cards {
  align-items: stretch;
}

.card {
  flex-basis: 25%;
  max-width: 25%;
}

.card__link {
  flex-direction: column;
  height: 100%;
}

.card__image {
  width: 100%;
  height: auto;
  object-fit: none;
}

.card__title {
   font-size: pxToRem(16);
   line-height: 1.39;
   padding: pxToRem(22) pxToRem(20);
   min-height: pxToRem(135);
}

// Resources variation
.cards-section--resources {
  .card {
    flex-basis: calc((100% / 3) - 10px);
    margin-bottom: pxToRem(18);
    max-width: none;
  }

  .card__link {
    padding-right: pxToRem(100);
    min-height: pxToRem(120);
    display: flex;
    justify-content: center;
  }

  .card__title {
    font-size: pxToRem(20);
    min-height: 0;
  }
}
