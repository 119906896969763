/* Module: S */
.search-results {
  .container {
    width: 86%;
  }
}

.search-results__summary {
  margin-bottom: pxToRem(31);
  font-size: pxToRem(17);
}

.search-results__list {
  margin-bottom: pxToRem(62-7);
}

.search-results__item {
  padding: pxToRem(45) pxToRem(28) pxToRem(26) pxToRem(70);
  margin-bottom: pxToRem(7);
}

.search-results__item-title {
  font-size: pxToRem(30);
  margin-bottom: pxToRem(32);
  padding-right: pxToRem(123);
}

.search-reults__item-desc {
  padding-right: pxToRem(123);
}

.search-results__read-more {
  font-size: pxToRem(16);
  padding-right: pxToRem(40);
  margin-top: 0;
}

// Pagination
.pagination {
  font-size: pxToRem(17);
}

.pagination__item {
  margin: 0 pxToRem(7);
  &.current {
    padding-bottom: pxToRem(5);
    padding: 0 pxToRem(10);
  }
}

.pagination__link {
  padding: 0 pxToRem(10);
}

.pagination__nav {
  padding: 0 pxToRem(10) pxToRem(3);
}
