/* Module: S */
.hindid {

    &-text-image {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    &-text-image__wrapper {
        flex-direction: row;
    }

    &-text-image__text {
        flex: 1 0 50%;
        padding-right: 2rem;
        padding-left: 3rem;
    }

    &-text-image__image {
        flex: 0 1 50%;
        margin: -2rem -8% -2rem 0;
    }

}