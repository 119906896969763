/* Module: S */
.regular-content .accordion {

  &__item {
    padding: 0;

    &::before {
      display: none;
    }

    + .accordion__item {
      margin-top: .25rem;
    }

    &:nth-child(odd) .accordion__link {
      background-color: $white-five;

      .no-js & {
        background-color: $warm-grey-two;
        color: $white;
      }

      &:hover,
      &:focus {
        background-color: darken($white-five, 10%);
      }
    }

    &:nth-child(odd) .accordion__link--active {
      background-color: $warm-grey-two;

      &:hover,
      &:focus {
        background-color: $warm-grey-two;
      }
    }
  }

  &__link {
    background-color: $white;
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 1.4375rem 3.75rem 1.4375rem 1.75rem;
    position: relative;
    transition: background-color .15s ease-in-out;

    .no-js & {
      background-color: $warm-grey-two;
      color: $white;

      &::before {
        display: none;
      }
    }

    &:hover,
    &:focus {
      background-color: darken($white, 10%);
      border: 0;
    }

    &::before {
      @include sq-sprite-bg(1);
      @include sq-sprite('plus', 1);
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-1.25rem);
    }
  }

  &__link--active {
    background-color: $warm-grey-two;
    color: $white;

    &:hover,
    &:focus {
      text-decoration: underline;
      background-color: $warm-grey-two;
    }

    &::before {
      @include sq-sprite('minus', 1);
    }
  }

  &__target {
    display: none;
    padding: 2.25rem 1.75rem;

    .no-js & {
      display: block;
    }

    *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }

    iframe {
      width: 100%;
    }
  }
}

a.accordion__link {
  color: $black-four;
}
