/* Module: S */
// FORMS
.hin-form {
  width: 50%;
}

.section--indentation {
  padding-left: pxToRem(100);
  padding-right: pxToRem(100);
}



// LHS and CONTENT
.section--regular-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 73.75rem;
  padding: 3.625rem 0 0;
  width: 86%;
}

.lhs-menu + .regular-content {
  flex-basis: 67%;
  max-width: 67%;
}

.regular-content {
  flex-basis: 100%;
  max-width: 100%;

  > *:first-child {
    margin-top: 0;
  }
}
