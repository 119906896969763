/* Module: S */
.page-header {
  padding-bottom: 4.625rem;
  padding-top: 5.25rem;

  h1 {
    font-size: 3.25rem;
  }
}

.breadcrumbs {
  display: block;
}

.breadcrumbs__item {
  display: inline-block;

  &:nth-last-child(1) {
    display: inline-block;
  }
}
