/* Module: S */
.header {
  display: flex;
  justify-content: space-between;
  transition: background-color .5s;

  &::before {
    background-color: $very-light-pink;
    content: '';
    height: 100%;
    position: absolute;
    transform: translateY(-100%);
    transition: transform .15s ease-in-out;
    width: 100%;
    will-change: transform;
  }

  &.scrolled {

    &::before {
      transform: translateY(0);
    }
  }

  .homepage & {
    background-color: transparent;
  }
}

.logo {
  float: none;
  padding: 0 3.4375rem;

  &-text {
    font-size: 1.25rem;
  }
}

.header-search {
  background-image: none;
  float: none;
  order: 3;
  padding: 0 1.375rem;

  &__form {
    display: block;
  }

  &__link {
    display: none;
  }

  &__input {
    padding-right: pxToRem(40);
  }
}
