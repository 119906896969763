/* Module: S */
// TYPOGRAPHY
a {
    text-decoration: none;
}

h1,
.h1 {
    color: $black-two;
    font-size: pxToRem(53);
    font-weight: 600;
    margin: 0;
}

h2,
.h2 {
    color: $black-two;
    font-size: pxToRem(32);
    font-weight: 600;
    margin: 1rem 0;
}

h3,
.h3 {
    color: $black-two;
    font-size: pxToRem(26);
    font-weight: 600;
    margin: 1.125rem 0;
}

h4,
.h4 {
    color: $black-two;
    font-size: pxToRem(20);
    font-weight: 600;
    margin: 1.125rem 0;
}

h5,
.h5 {
    color: $black-two;
    font-size: pxToRem(17);
    font-weight: 600;
    margin: 1rem 0;
}

h6,
.h6 {
    color: $black-two;
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem 0;
}

.caption {
    color: $black-four;
    display: inline-block;
    font-size: pxToRem(13);
    font-weight: 600;
    margin-top: pxToRem(5);
}

.medium {
    color: $black-three;
    font-size: pxToRem(18);
    font-weight: 500;
    line-height: pxToRem(29);
    text-align: left;
}

p {
    color: $black-three;
    font-size: pxToRem(15);
    font-weight: 400;
    line-height: pxToRem(25);
    text-align: left;
}

.big {
    color: $black-three;
    font-size: pxToRem(20);
    font-weight: 400;
    line-height: pxToRem(30);
    text-align: left;
}

.indent {
    border-left: pxToRem(6) solid $asparagus;
    margin-left: .9375rem;
    padding: .625rem 0 .625rem 2.5rem;
}

// GRID HELPERS
.section--regular-content {
    padding: pxToRem(49) pxToRem(25);
}

.lhs-menu__wrapper {
    flex-basis: 33%;
    width: 33%;
}

.lhs-menu__wrapper+.section--regular-content {
    flex-basis: 67%;
    width: 67%;
}

// LISTS
.regular-content {
    ol,
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    li {
        color: $black-four;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.5625rem;
        padding-left: 1.5625rem;
        +li {
            margin-top: 1rem;
        }
    }
    ul li {
        position: relative;
        &::before {
            color: $warm-grey-two;
            content: '\2022';
            font-size: 1.125rem;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
    ul ul {
        margin: 2rem 0;
    }
    ul ul li {
        list-style-type: none;
        &::before {
            content: '\05AF';
            font-size: 1.125rem;
            left: pxToRem(-2);
            top: pxToRem(8);
        }
    }
    ol {
        counter-reset: list-item-counter;
    }
    ol li {
        position: relative;
        &::before {
            color: $warm-grey-two;
            content: counter(list-item-counter)'.';
            counter-increment: list-item-counter;
            display: block;
            font-size: 0.9375rem;
            left: 0;
            line-height: 1.5625rem;
            position: absolute;
            top: 0;
        }
    }
}

// BUTTONS
.btn,
.regular-content .btn,
.regular-content button,
.hin-form input[type="submit"] {
    border-radius: 0;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: pxToRem(16);
    font-weight: 600;
    margin-right: pxToRem(19);
    padding: pxToRem(14) pxToRem(46);
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.btn--green,
.hin-form input[type="submit"],
.regular-content .btn--green {
    background-color: $off-green;
    border: pxToRem(2) solid $off-green;
    position: relative;
    &:hover,
    &:focus {
        background-color: transparent;
        border: pxToRem(2) solid $off-green;
        color: $off-green;
    }
    &--search {
        align-items: center;
        display: flex;
        height: pxToRem(60);
        justify-content: center;
        padding: 0;
        position: relative;
        text-indent: -9999999px;
        width: pxToRem(60);
        &:focus,
        &:hover {
            background-color: $off-green;
            border: pxToRem(2) solid $off-green;
        }
        &::after {
            @include sq-sprite-bg(1);
            @include sq-sprite('arrow-right-white', 1);
            content: '';
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translateX(50%) translateY(-50%);
        }
    }
}

.btn--grey,
.regular-content .btn--grey {
    background-color: $warm-grey-two;
    border: pxToRem(2) solid $warm-grey-two;
    &:hover {
        background-color: transparent;
        border: pxToRem(2) solid $warm-grey-two;
        color: $warm-grey-two;
    }
}

main {
    a.regular {
        background-position: right pxToRem(4);
        background-repeat: no-repeat;
        color: $black-five;
        font-size: pxToRem(15);
        font-weight: 600;
        padding-bottom: pxToRem(3);
        &:focus,
        &:hover {
            border-bottom: pxToRem(2) solid $black-five;
        }
    }
    .green,
    .external,
    .pdf {
        position: relative;
        font-weight: 600;
        color: $black-five;
        &::after {
            @include sq-sprite-bg(1);
            content: '';
            position: absolute;
            top: .125rem;
        }
    }
    .green {
        color: $off-green;
        padding-right: pxToRem(36);
        &:focus,
        &:hover {
            border-bottom: pxToRem(2) solid $off-green;
        }
        &::after {
            @include sq-sprite('arrow-small-right-green', 1);
            right: 0;
        }
    }
    .external {
        &::after {
            @include sq-sprite('external', 1);
            right: 0;
            position: relative;
            display: inline-block;
            margin-left: pxToRem(9);
        }
    }
    .pdf {
        padding-left: pxToRem(23);
        &::after {
            @include sq-sprite('pdf', 1);
            left: 0;
            height: 17px;
        }
    }
}

// TABLES
.table__wrapper {
    max-width: 100%;
    overflow-x: auto;

    &--scrollinfo {
        display: none;
        font-size: 12px;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        display: block;
        background-color: #f1ece8;
        padding: 5px 0;
        font-weight: bold;
    }
}

th,
th p {
    background-color: $warm-grey-two;
    color: $white;
    font-size: pxToRem(16);
    font-weight: bold;
    line-height: pxToRem(30);
    padding: pxToRem(25) pxToRem(13) pxToRem(25) pxToRem(12);
    text-align: left;
    &:not(:last-of-type) {
        border-right: pxToRem(4) solid $white;
    }
}

th p {
    margin: 0;
    padding: 0;
}

tbody tr:nth-child(even) td {
    background-color: $white-six;
}

tbody td {
    border: 0;
    color: $black-four;
    font-size: pxToRem(15);
    font-weight: normal;
    line-height: pxToRem(16);
    padding: pxToRem(14) pxToRem(19);
}

tbody td:not(:last-of-type) {
    border-right: pxToRem(4) solid $white;
}

// Images & Videos
.images {
    display: flex;
    flex-flow: column;
    margin-bottom: pxToRem(24);
    &__image {
        margin-bottom: pxToRem(16);
        p {
            position: relative;
        }
        img {
            display: block;
        }
    }
    &--single {
        display: block;
    }
    &--single &__image {
        max-width: 100%;
    }
}

// FORMS
.hin-form {
    width: 100%;
    &--resetpasswd {
        .form__item {
            input:nth-child(2) {
                margin-bottom: pxToRem(20);
            }
        }
    }
    .sq-form-question,
    .sq-form-control,
    .form__item {
        margin-top: pxToRem(35);
    }
    .sq-form-captcha {
        margin-bottom: pxToRem(35);
    }
    .sq-form-question.invalid {
        input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
            border-color: $scarlet;
        }
    }
    .form__char-counter {
        color: $black-four;
        display: block;
        margin: pxToRem(13) 0 0;
        padding: 0;
        text-align: center;
    }
    &.limit .form__char-counter {
        color: $scarlet;
        display: block;
        position: relative;
        .limit-info {
            left: 0;
            position: absolute;
        }
    }
    .form__alert--invalid {
        color: $reddish;
        font-size: pxToRem(15);
    }
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }
    legend {
        visibility: hidden;
    }
    label {
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        color: $black-six;
        display: block;
        font-size: pxToRem(15);
        font-weight: 500;
        user-select: none;
    }
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
    textarea {
        border: pxToRem(1) solid $greyish;
        color: $black-seven;
        font-size: pxToRem(15);
        padding: pxToRem(16);
        transition: border-color .15s ease-in-out;
        width: 100%;
        &:focus,
        &:hover {
            border-color: $greyish-brown-three;
            outline: 0;
        }
    }
    .form__option-list,
    ul {
        input[type="radio"] {
            display: none;
            &:checked {
                +label::before {
                    display: block;
                }
            }
        }
        label {
            cursor: pointer;
            padding-left: pxToRem(20);
            position: relative;
            &::before {
                background-color: $off-green;
                border-radius: pxToRem(50);
                content: '';
                cursor: pointer;
                display: none;
                height: pxToRem(10);
                left: pxToRem(3);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: pxToRem(10);
                z-index: 99;
            }
            &::after {
                border: pxToRem(1) solid $warm-grey-three;
                border-radius: pxToRem(50);
                content: '';
                cursor: pointer;
                display: block;
                height: pxToRem(16);
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: pxToRem(16);
                z-index: 97;
            }
        }
    }
    .form__check-list,
    ul {
        input[type="checkbox"] {
            display: none;
            &:checked {
                +label:before {
                    display: block;
                }
            }
        }
        label {
            cursor: pointer;
            padding-left: pxToRem(20);
            position: relative;
            &::before {
                background-color: transparent;
                background-image: url('{{file_dest}}/tick.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80%;
                content: '';
                cursor: pointer;
                display: none;
                height: pxToRem(16);
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: pxToRem(16);
                z-index: 99;
            }
            &::after {
                border: pxToRem(1) solid $warm-grey-three;
                content: '';
                cursor: pointer;
                display: block;
                height: pxToRem(16);
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: pxToRem(16);
                z-index: 97;
            }
        }
    }
    .form__check-list,
    ul,
    .form__option-list {
        span {
            &::after,
            &::before {
                content: '';
                display: block;
            }
        }
    }
    .sq-form-question.file,
    .form__item.file {
        border-bottom: pxToRem(1) solid $black-four;
        padding-bottom: pxToRem(5);
        padding-top: pxToRem(47);
        position: relative;
        input[type="file"] {
            display: none;
        }
        label {
            background-repeat: no-repeat;
            border: pxToRem(1.5) solid $off-green;
            color: $off-green;
            content: 'Choose file';
            cursor: pointer;
            font-size: pxToRem(14);
            padding: pxToRem(10) pxToRem(38) pxToRem(11) pxToRem(16);
            position: relative;
            right: 0;
            top: 0;
            &::after {
                @include sq-sprite-bg(1);
                @include sq-sprite('upload',
                1);
                content: '';
                position: absolute;
                right: .875rem;
                top: .75rem;
            }
        }
    }
    textarea {
        min-height: pxToRem(150);
    }
    .limit {
        input,
        textarea {
            border-color: $scarlet;
        }
        .form__char-counter {
            color: $scarlet;
        }
    }
    ul {
        @include sq-list-reset;
        &::after {
            clear: both;
            content: '';
            display: block;
            float: none;
        }
        li {
            float: left;
            margin-right: pxToRem(35);
            padding-left: 0;
            &::before {
                display: none;
            }
            +li {
                margin-top: 0;
            }
        }
        label {
            display: inline-block;
            margin-left: pxToRem(10);
        }
    }
}

// WEBSITE ELEMENTS
html {
    overflow-x: hidden;
}

html.drawer-opened {
  overflow: hidden;
}

body:not(.homepage) {
    padding-top: 4.5rem;
}

iframe {
    max-width: 100%;
}