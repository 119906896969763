/* Module: S */
.interactive__tab-title--mobile {
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  &.sticky {
    position: fixed;
    top: 8.25rem;
    z-index: 200;
    background-color: $white;
  }

  img {
    display: block;
    margin-right: 1.25rem;
  }
}

.interactive__tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.sticky {
    background-color: $white;
    position: fixed;
    top: 4.5rem;
    left: 0;
    right: 0;
    z-index: 201;
  }

  *:focus {
    outline: none;
  }
}
.interactive__tab {
  counter-increment: tab;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 3.75rem;
  color: transparent;

  &::before {
    content: counter(tab);
    display: block;
    position: absolute;
    color: $black;
    font-weight: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    flex-direction: column;
    position: relative;
    text-align: center;
    height: 100%;
    max-width: 100%;
    width: 100%;

    span {
      position: absolute;
      max-width: 100%;
      line-height: 1rem;
    }
  }
}

.interactive__tab.active,
.interactive__tab:hover {
  background-color: $asparagus;

  &::before {
    color: $white;
  }

  &::after {
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: $asparagus;
    top: 100%;
    left: 50%;
    content: "";
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 10;
  }

  a {
    color: transparent;
  }
}

.interactive__tab {

  a:focus {
    background-color: $asparagus;
    color: $white;
  }
}
