// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #272727;

// Selection highlighting
$content-selection-highlight: #b3d4fc;


// Design colors
$warm-grey: #8b8b8b;
$white: #ffffff;
$very-light-pink: #fff9f5;
$warm-grey-two: #9b8c7e;
$beige: #f4eae3;
$black: #1b1b1b;
$brownish-grey: #636363;
$white-two: #f1ece8;
$black-two: #272727;
$black-three: #000000;
$black-four: #363636;
$asparagus: #7db266;
$off-green: #699c53;
$black-five: #3a3a3a;
$white-three: #fbfbfb;
$scarlet: #d0011b;
$black-six: #2a2a2a;
$reddish: #bc3737;
$black-seven: #121212;
$white-four: #d8d8d8;
$greyish: #adadad;
$white-five: #f7f7f7;
$dusty-blue: #5193a3;
$white-six: #fafafa;
$white-seven: #f0ebe8;
$white-eight: #e2e2e2;
$black-eight: #202020;
$pinkish-grey: #c1c1c1;
$black-nine: #232323;
$pinkish-grey-two: #bbbbbb;
$sage: #8eb167;
$black-ten: #313131;
$black-eleven: #2c2c2c;
$black-twelve: #262626;
$pinkish-grey-three: #e4dad4;
$black-thirteen: #323232;
$black-fourteen: #2f2f2f;
$dark-peach: #d57564;
$brownish-grey-two: #7c6d5f;
$greyish-brown: #5f5246;
$greyish-brown-two: #392e26;
$greyish-brown-three: #474747;
$divider-gray: #b7b7b7;
$warm-grey-three: #9d9d9d;
$pink-card-border: #c7b4a2;

