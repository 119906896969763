/* Module: S */
$brown-border: #67594d;
$dark-border: #0d2034;

.global-menu {
  color: $white;
  float: right;
  height: 4.5rem;
  order: 3;

  &__trigger {
    opacity: 0;
    position: absolute;
  }

  &__trigger:checked ~
  &__list {
    display: block;
  }

  &__trigger:checked +
  &__trigger-label {
    background-color: $white;

    &::after {
      @include sq-sprite('close-green', 1);
    }
  }

  &__trigger-label {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    transition: background-color .15s ease-in-out;
    width: 4.375rem;

    &::before {
      background-color: $beige;
      content: '';
      display: block;
      height: 3.5rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    &::after {
      @include sq-sprite-bg(1);
      @include sq-sprite('hamburger', 1);
      content: '';
    }
  }

  &__list,
  .submenu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
  }

  &__list {
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    max-height: calc(100vh - 4.5rem);
    overflow-y: auto;
  }

  &__link,
  .submenu__link {
    color: inherit;
    display: block;
  }

  &__link {
    background-color: $warm-grey-two;
    font-size: .9375rem;
    padding: 1.1875rem 3.5rem 1.1875rem 1.5rem;
    transition: background-color .15s ease-in-out;

    &:hover,
    &:focus {
      background-color: darken($warm-grey-two, 10%);
    }

    &.selected {
      background-color: darken($warm-grey-two, 10%);
      font-weight: 700;
    }
  }

  &__item,
  .submenu__item {
    position: relative;
  }

  &__item +
  &__item {
    border-top: 1px solid $dark-border;
  }

  .submenu__trigger,
  .submenu__trigger-label {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  .submenu__trigger {
    opacity: 0;
  }

  .submenu__trigger-label {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    transition: background-color .15s ease-in-out;
    width: 2.6875rem;

    &::after {
      @include sq-sprite-bg(1);
      content: '';
    }
  }

  .submenu__trigger:checked {
    ~ .submenu__list {
      display: block;
    }

    + .submenu__trigger-label:not(.menu-lvl2__trigger-label):after {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    + .menu-lvl2__trigger-label::after {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translatex(-50%);
    }

    + .menu-lvl2__trigger-label::after,
    + .menu-lvl3__trigger-label::after,
    + .menu-lvl4__trigger-label::after {
      @include sq-sprite('menu-white-up', 1);
    }

  }


  .checked ~ .submenu > .submenu__trigger:not(:checked) {

    + .submenu__trigger-label:not(.menu-lvl2__trigger-label):after {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    + .menu-lvl2__trigger-label::after {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translatex(-50%);
    }

    + .menu-lvl2__trigger-label::after,
    + .menu-lvl3__trigger-label::after,
    + .menu-lvl4__trigger-label::after {
      @include sq-sprite('menu-white-up', 1);
    }
  }

  .checked ~ .submenu > .submenu__trigger:checked {

    + .menu-lvl2__trigger-label::after {
      @include sq-sprite('menu-white-left', 1);
    }

    + .menu-lvl2__trigger-label::after {
      position: static;
      top: auto;
      left: auto;
      transform: none;
    }

    + .menu-lvl3__trigger-label::after,
    + .menu-lvl4__trigger-label::after {
      @include sq-sprite('menu-white-down', 1);
    }
  }

  .submenu__list {
    display: none;
  }

  .submenu__link {
    font-size: .875rem;
    padding: .75rem 3.5rem .75rem 1.8125rem;
  }

  .menu-lvl2__item {

    + .menu-lvl2__item {
      border-top: 1px solid $brown-border;
    }
  }

  .menu-lvl3__item {

    + .menu-lvl3__item {
      border-top: 1px solid $greyish-brown-two;
    }
  }

  .menu-lvl4__item {

    + .menu-lvl4__item {
      border-top: 1px solid $greyish-brown;
    }
  }

  .menu-lvl2__trigger-label {
    background-color: $warm-grey-two;
    height: 100%;

    &:hover,
    &:focus {
      background-color: darken($warm-grey-two, 10%);
    }

    &::after {
      @include sq-sprite('menu-white-left', 1);
    }
  }

  .menu-lvl3__trigger-label,
  .menu-lvl4__trigger-label {

    &::after {
      @include sq-sprite('menu-white-down', 1);
    }
  }

  .menu-lvl3__trigger-label {
    background-color: $brownish-grey-two;
    border-left: 1px solid $brown-border;

    &:hover,
    &:focus {
      background-color: darken($brownish-grey-two, 10%);
    }
  }

  .menu-lvl4__trigger-label {
    background-color: $greyish-brown;
    border-left: 1px solid $greyish-brown-two;

    &:hover,
    &:focus {
      background-color: darken($greyish-brown, 10%);
    }
  }

  .menu-lvl2__link {
    background-color: $brownish-grey-two;

    &:hover,
    &:focus {
      background-color: darken($brownish-grey-two, 10%);
    }

    &.selected {
      background-color: darken($brownish-grey-two, 10%);
      font-weight: 700;
    }
  }

  .menu-lvl3__link {
    background-color: $greyish-brown;
    padding-left: 3.0625rem;

    &:hover,
    &:focus {
      background-color: darken($greyish-brown, 10%);
    }

    &.selected {
      background-color: darken($greyish-brown, 10%);
      font-weight: 700;
    }
  }

  .menu-lvl4__link {
    background-color: $greyish-brown-two;
    padding-left: 4.3125rem;

    &:hover,
    &:focus {
      background-color: darken($greyish-brown-two, 10%);
    }

    &.selected {
      background-color: darken($greyish-brown-two, 10%);
      font-weight: 700;
    }
  }
}

.checked + .submenu > .submenu__trigger:not(:checked) ~ .submenu__list {
  display: block;
}

.checked + .submenu > .submenu__trigger:checked ~ .submenu__list {
  display: none;
}
