/* Module: S */
.indent {
  margin-left: 4.375rem;
}



// Videos & Images
.images {
  flex-flow: row;
  margin-bottom: pxToRem(48);

  &__image {
    flex: 1 0 40%;
    max-width: 40%;
  }

  &__text {
    padding-left: pxToRem(100);

    p:first-child {
      margin-top: 0;
    }
  }

  &--right {
    flex-direction: row-reverse;
  }

  &--right &__text {
    padding-left: 0;
    padding-right: pxToRem(100);
  }

  &--single {
    flex-flow: column;

    .images__text {
      padding-left: 0;
    }
  }
}

.hin-form {
  .sq-form-question.file, .form__item.file {
    padding-top: pxToRem(27);

    label {
      position: absolute;
    }
  }
}



// BUTTONS
.btn,
.regular-content .btn,
.hin-form input[type="submit"] {
  padding: 1rem 5.5rem .875rem;
}

.btn--green,
.regular-content .btn--green,
.hin-form input[type="submit"] {

  &--back,
  &--search {
    background-image: none;
    display: inline-block;
    height: auto;
    padding: 1rem 4.5rem .875rem 2.25rem;
    text-indent: 0;
    width: auto;

    &::after {
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-1.75rem);
      transition: transform .15s ease-in-out;
      will-change: opacity, right;
    }

    &:focus,
    &:hover {
      color: $white;

      &::after {
        transform: translateY(-50%) translateX(-1rem);
      }
    }
  }

  &--back {
    padding: 1rem 2.25rem .875rem 4.5rem;
    position: relative;

    &::after {
      @include sq-sprite-bg(1);
      @include sq-sprite('arrow-left-white', 1);
      content: '';
      left: 0;
      position: absolute;
      right: auto;
      transform: translateY(-50%) translateX(1.75rem);
    }

    &:focus,
    &:hover {
      background-color: $off-green;

      &::after {
        transform: translateY(-50%) translateX(1rem);
      }
    }
  }
}
