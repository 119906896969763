/* Module: S */
.active-video-banner {
    overflow: hidden;
    position: relative;
    &-inner {
        margin:     0 auto;
        max-width:  pxToRem(920);
        position:   relative;
        text-align: center;
        z-index:    1;
    }

    &.image-only {
        video, button {
            display: none;
        }
        .active-video-banner__video-bg--fallback {
            display: block;
            visibility: visible;
        }
    }

    &__text-wrapper {
        margin-top: pxToRem(48);
        text-align: center;
        .active-video-banner__heading,
        .active-video-banner__subheading {
            color:          $white;
            word-break:     break-word;
            font-family: 'VIC';
            text-align: left;
        }
        .active-video-banner__heading {
            font-size:   pxToRem(30);
            font-weight: 600;
            line-height: pxToRem(35);
            margin-bottom: pxToRem(10);
        }
        .active-video-banner__subheading {
            font-size: pxToRem(15);
            font-weight: 500;
            line-height: pxToRem(24);
        }
    }
    &__toggle-button {
        display: none;
    }
    &__button-wrapper {
        display:    inline-block;
        margin-top:     pxToRem(30);
        text-align: center;

        .btn {
            width: 100%;
            margin-top:     pxToRem(35);
            margin-bottom:  pxToRem(60);

            &:hover,
            &:focus,
            &:active {
              background-color: $white;
            }
        }
    }
    &__video-bg--fallback,
    &__video-bg--loop {
        height:   auto;
        left:     0;
        position: absolute;
        top:      0;
        width:    100%;
    }
    &__video-bg--fallback {
        background-position: center center;
        background-size: cover;
        display:         block;
        height:          100%;
        visibility:      visible;
    }
    &__video-bg--loop {
        display:    none;
        visibility: hidden;
    }
    &__overlay-black {
        opacity:          .5;
        width:            100%;
        height:           100%;
        position:         absolute;
        background-color: $black;
        background-size:  cover;
        left:             0;
        top:              0;
    }

    .scroll-to-tabs {
      display: block;
      margin: auto;
      margin-bottom: pxToRem(16);
      width: 1.25rem;
      height: 2.125rem;
      border: 1px solid $white;
      border-radius: 32%;
      position: relative;

      &::after {
        content: "";
        width: 2px;
        height: 9px;
        background-color: $white;
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover,
      &:focus,
      &:active, {
        background-color: $white;

        &::after {
          background-color: $asparagus;
        }
      }
    }
}
