/* Module: S */
.hindid {

    .hindid__wrapper + &__wrapper,
    &__wrapper,
    &__wrapper-title {
        padding: 2rem 7%;
        position: relative;

        &--light {
            background-color: $white-seven;

            &::before,
            &::after {
                background-color: $warm-grey-two;
            }
        }

        &--dark {
            background-color: $warm-grey-two;

            &::before,
            &::after {
                background-color: $white-seven;
            }
        }

        // Dot
        &::before {
            content: '';
            display: block;
            width: 1.0625rem;
            height: 1.0625rem;
            border-radius: 50%;
            position: absolute;
            top: 3.75rem;
        }

        // Line
        &::after {
            content: '';
            display: block;
            width: 1px;
            position: absolute;
            top: 0;
            bottom: 0;
            transform: translateX(8px);
        }

        &--nodot {
            &::before,
            &::after {
                content: '';
                display: none;
            }
        }
    }

    &-tab-content__inner {
        > .hindid__ct:last-child {
            &::after {
                bottom: auto;
                height: 4.375rem;
            }
        }

        > section:first-of-type {
            &::after {
                bottom: 0 !important;
                height: auto !important;
            }
        }
    }

    &__wrapper {
        &::after {
            top: 75px;
        }
    }

    &__wrapper-title {
      display: none;
    }

    &-tab-content {
      display: none;

      &.active {
        display: block;
      }
    }

    .no-js &-tab-content {
        display: block;
    }

    .no-js &_next {
        display: none;
    }

    &--button {
        margin: 0 auto;
        display: block;
        text-align: center;
        position: relative;
        z-index: 20;

        &::after {
            @include sq-sprite-bg(1);
            @include sq-sprite('arrow-right-white', 1);
            content: '';
            position: absolute;
            right: 1.2rem;
            top: 50%;
            margin-top: -8px;
        }

        &:hover::after,
        &:focus::after {
            @include sq-sprite('arrow-right-black', 1);
        }

        &:hover,
        &:focus {
            background-color: $white;
        }
    }
}

.hindid-tab-content__inner {
    h2 {
        font-size: 3.125rem;
    }

    h3 {
        font-size: 2rem;
    }
}